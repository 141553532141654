import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Input } from "../../shared";
import { setCommentTime } from "../actions";
import { getUrl } from "../../../getBackend";
import { HourComment } from "../HourComment";
import { debounce } from "lodash";
import { TicketSummary } from "../models/TicketSummary";

interface IProps {}

interface ITotalResponse {
    total_seconds: number | null;
    jira_ticket: {
        id: string;
        key: string;
        status: string;
        story_points: number | null;
        summary: string;
    } | null;
}

const mapStateToProps = (state: any) => ({
    comment: state.register.time.comment,
    projectId: state.register.project,
});

const mapDispatchToProps = {
    setCommentTime,
};

type Props = IProps &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

const CommentInput: React.FC<Props> = (props) => {
    const [tickets, setTickets] = React.useState<TicketSummary[]>([]);
    const [total, setTotal] = useState<ITotalResponse | null>(null);

    const totalHours =
        total && total.total_seconds
            ? Math.round((total.total_seconds / 60 / 60) * 100) / 100
            : 0;

    React.useEffect(() => {
        if (!props.projectId) {
            return;
        }
        axios
            .get(
                `${getUrl()}/api/v1/hours/summary/?project_id=${
                    props.projectId
                }&max_results=3`
            )
            .then((res) => {
                setTickets(res.data);
            });
    }, [props.projectId]);

    useEffect(() => {
        onCommentChange(props.comment, props.projectId);
    }, [props.comment, props.projectId]);

    const fetch = (newComment: string, projectId: string) => {
        if (newComment === "" || !projectId) {
            setTotal(null);
            return;
        }

        axios
            .get<ITotalResponse>(
                `${getUrl()}/api/v1/hours/total/?comment=${newComment}&project_id=${projectId}`
            )
            .then((res) => {
                setTotal(res.data);
            });
    };

    const onCommentChange = useCallback(debounce(fetch, 1000), []);

    return (
        <React.Fragment>
            <Input
                id="comment"
                placeholder="Comment"
                onChange={(e: any) => {
                    const comment = e.target.value;
                    props.setCommentTime(comment);
                }}
                value={props.comment}
                label={undefined}
                type={undefined}
                required={undefined}
                help={undefined}
            />

            <div style={{ marginBottom: "0.75rem" }}>
                <HourComment
                    hour={{
                        comment: props.comment,
                        comment_total: totalHours,
                        project: props.projectId || undefined,
                    }}
                >
                    <div
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        Total: {totalHours} hour(s)
                        {total && total.jira_ticket
                            ? ` - ${total.jira_ticket.summary}`
                            : ""}
                    </div>
                </HourComment>

                <hr />

                {tickets.map((ticket) => (
                    <div
                        key={ticket.key}
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                            props.setCommentTime(ticket.key);
                        }}
                    >
                        <div style={{ flex: 1 }}>{ticket.key}</div>
                        <div
                            style={{
                                flex: 3,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {ticket.summary}
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentInput as any);
