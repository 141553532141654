import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import enhanceWithClickOutside from "react-click-outside";
import { registerSelectProject } from "../actions";

export class Project extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.selectProject = this.selectProject.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    selectProject(id) {
        this.props.registerSelectProject(id);
        this.toggleDropdown();
    }

    toggleDropdown() {
        this.setState({
            open: !this.state.open,
        });
    }

    handleClickOutside() {
        this.setState({ open: false });
    }

    render() {
        if (!this.props.selectedClientId) {
            return (
                <div
                    className="dropdown"
                    style={{ width: "100%", marginBottom: "1em" }}
                >
                    <div className="dropdown-trigger" style={{ width: "100%" }}>
                        <button
                            type="button"
                            className="button is-fullwidth"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                        >
                            <span>Select a client first</span>
                        </button>
                    </div>
                </div>
            );
        }

        const options = this.props.projects
            .filter(
                (project) => project.client_id === this.props.selectedClientId
            )
            .map((project) => (
                <span
                    role="button"
                    key={project.id}
                    className="dropdown-item"
                    onClick={() => this.selectProject(project.id)}
                >
                    {project.name}
                </span>
            ));

        const currentProject =
            this.props.selectedProjectId &&
            this.props.projects.find(
                (project) => project.id === this.props.selectedProjectId
            );

        return (
            <div
                role="button"
                className={this.state.open ? "dropdown is-active" : "dropdown"}
                style={{ width: "100%", marginBottom: "1em" }}
                onClick={() => this.toggleDropdown()}
            >
                <div className="dropdown-trigger" style={{ width: "100%" }}>
                    <button
                        className="button is-fullwidth"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>
                            {currentProject
                                ? currentProject.name
                                : "Select project"}
                        </span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div
                    className="dropdown-menu"
                    id="dropdown-menu"
                    role="menu"
                    style={{ width: "100%" }}
                >
                    <div className="dropdown-content">{options}</div>
                </div>
            </div>
        );
    }
}

Project.propTypes = {
    projects: PropTypes.array,
    selectedClientId: PropTypes.string,
    selectedProjectId: PropTypes.string,
    registerSelectProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    projects: state.projects.projects.results.filter(
        (project) => project.enabled
    ),
    selectedClientId: state.register.client,
    selectedProjectId: state.register.project,
});

const mapDispatchToProps = { registerSelectProject };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(enhanceWithClickOutside(Project));
