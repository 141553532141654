import React, { Component } from "react";
import PropTypes from "prop-types";
import enhanceWithClickOutside from "react-click-outside";

import { connect } from "react-redux";

import { selectRate } from "../actions";

export class Rate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.selectRate = this.selectRate.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    selectRate(id) {
        this.props.selectRate(id);
        this.toggleDropdown();
    }

    toggleDropdown() {
        this.setState({
            open: !this.state.open,
        });
    }

    handleClickOutside() {
        this.setState({ open: false });
    }

    render() {
        if (!this.props.selectedProjectId) {
            return (
                <div
                    className="dropdown is-hoverable"
                    style={{ width: "100%", marginBottom: "1em" }}
                >
                    <div className="dropdown-trigger" style={{ width: "100%" }}>
                        <button
                            type="button"
                            className="button is-fullwidth"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                        >
                            <span>Select a project first</span>
                        </button>
                    </div>
                </div>
            );
        }

        const ratesList = this.props.rates.map((item) => (
            <span
                key={item.id}
                className="dropdown-item"
                onClick={() => this.selectRate(item.id)}
            >
                {item.name}
            </span>
        ));

        const selectedRate =
            this.props.selectedRate &&
            this.props.rates.find(
                (item) => item.id === this.props.selectedRate
            );

        return (
            <div
                role="button"
                className={this.state.open ? "dropdown is-active" : "dropdown"}
                style={{ width: "100%", marginBottom: "1em" }}
                onClick={() => this.toggleDropdown()}
            >
                <div className="dropdown-trigger" style={{ width: "100%" }}>
                    <button
                        className="button is-fullwidth"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>
                            {selectedRate ? selectedRate.name : "Select a rate"}
                        </span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div
                    className="dropdown-menu"
                    id="dropdown-menu"
                    role="menu"
                    style={{ width: "100%" }}
                >
                    <div className="dropdown-content">{ratesList}</div>
                </div>
            </div>
        );
    }
}

Rate.propTypes = {
    selectRate: PropTypes.func.isRequired,
    selectedProjectId: PropTypes.string,
    selectedRate: PropTypes.string,
    rates: PropTypes.array,
};

const mapStateToProps = (state) => ({
    selectedProjectId: state.register.project,
    selectedRate: state.register.rate,
    rates: state.projects.projects.results.find(
        (item) => item.id === state.register.project
    )
        ? state.projects.projects.results
              .find((item) => item.id === state.register.project)
              .rates.filter((rate) => rate.enabled)
        : [],
});

const mapDispatchToProps = {
    selectRate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(enhanceWithClickOutside(Rate));
